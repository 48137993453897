import { deleteReq, get, post, put } from '../../../infra/rest';
import {
  GetCustomerSegmentsMetaData,
  GetCustomerSegments,
  GetCustomerSegment,
  GetCustomerSegmentContacts,
  GetCustomerSegmentsPaginationData,
  ExportCustomerSegmentResponse,
  CustomerSegmentType,
  GetCustomerSegmentAutocompleteValues,
  GetCustomerSegmentContactsCount,
  CustomerSegmentFilter,
  SheetsDataPayload,
  SheetMappingsRequest,
  FieldsData,
  ImportExcelFileStatusResponse,
  DeleteCustomersType,
} from '../typings';
import { formatObjectToFormDataKeyValuePairs } from '../utils/formatObjectToFormDataKeyValuePairs';

export const getCustomerSegmentsMetaData =
  async (): Promise<GetCustomerSegmentsMetaData> => {
    return get<GetCustomerSegmentsMetaData>(
      `/v2/customer/segment/filter-metadata`,
      undefined,
      true
    );
  };

export const getCustomerSegments = async (
  getCustomerSegmentsPaginationData?: GetCustomerSegmentsPaginationData
): Promise<GetCustomerSegments> => {
  return post<GetCustomerSegments>(
    `/v1/customer/segment/list`,
    {
      body: JSON.stringify({
        ...getCustomerSegmentsPaginationData,
        sortOptions: {
          ...getCustomerSegmentsPaginationData?.sortOptions,
          searchAfter:
            getCustomerSegmentsPaginationData?.sortOptions?.nextSearchAfter,
          nextSearchAfter: undefined,
        },
      }),
    },
    true
  );
};

export const getCustomerSegment = async (
  segmentId: string
): Promise<GetCustomerSegment> => {
  return get<GetCustomerSegment>(
    `/v1/customer/segment/${segmentId}`,
    undefined,
    true
  );
};

export const createCustomerSegment = async (
  segment: GetCustomerSegment
): Promise<GetCustomerSegment> => {
  return post<GetCustomerSegment>(
    `/v1/customer/segment`,
    { body: JSON.stringify(segment) },
    true
  );
};

export const updateCustomerSegment = async (
  segment: GetCustomerSegment
): Promise<GetCustomerSegment> => {
  return put<GetCustomerSegment>(
    `/v1/customer/segment/${segment.id}/update`,
    { body: JSON.stringify(segment) },
    true
  );
};

export const getCustomerSegmentContacts = async (
  customerSegmentId: string,
  customerSegmentType: CustomerSegmentType,
  getCustomerSegmentsPaginationData?: GetCustomerSegmentsPaginationData
): Promise<GetCustomerSegmentContacts> => {
  return post<GetCustomerSegmentContacts>(
    customerSegmentType === CustomerSegmentType.DYNAMIC_SEGMENT
      ? `/v1/customer/segment/filter-customers`
      : `/v1/customer/segment/${customerSegmentId}/customers`,
    {
      body: JSON.stringify({
        ...getCustomerSegmentsPaginationData,
        sortOptions: {
          ...getCustomerSegmentsPaginationData?.sortOptions,
          searchAfter:
            getCustomerSegmentsPaginationData?.sortOptions?.nextSearchAfter,
          nextSearchAfter: undefined,
        },
      }),
    },
    true
  );
};

export const getCustomerSegmentContactCounts = async (
  customerSegmentId: string,
  customerSegmentType: CustomerSegmentType,
  getCustomerSegmentsPaginationData?: GetCustomerSegmentsPaginationData
): Promise<GetCustomerSegmentContactsCount> => {
  return post<GetCustomerSegmentContactsCount>(
    customerSegmentType === CustomerSegmentType.DYNAMIC_SEGMENT
      ? `/v1/customer/segment/filter-customers/count`
      : `/v1/customer/segment/${customerSegmentId}/customers/count`,
    {
      body: JSON.stringify({
        ...getCustomerSegmentsPaginationData,
        sortOptions: {
          ...getCustomerSegmentsPaginationData?.sortOptions,
          searchAfter:
            getCustomerSegmentsPaginationData?.sortOptions?.nextSearchAfter,
          nextSearchAfter: undefined,
        },
      }),
    },
    true
  );
};

export const importCustomerSegmentExcel = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return post<void>(
    `/v1/groups/import-excel`,
    {
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    },
    true,
    false,
    false,
    true
  );
};

export const exportCustomerSegmentExcel = async (
  segmentId: string,
  filters: CustomerSegmentFilter | null,
  searchQuery: string | undefined,
  timezone: string
): Promise<ExportCustomerSegmentResponse> => {
  return post<ExportCustomerSegmentResponse>(
    `/v1/groups/export-excel/members`,
    {
      body: JSON.stringify({
        groupId: segmentId,
        filters,
        search: searchQuery,
        timezone,
      }),
    },
    true
  );
};

export const getCustomerSegmentFilterValueOptions = async ({
  fieldId,
  search,
  nextSearchAfter,
}: {
  fieldId: string;
  search?: string;
  nextSearchAfter?: string[];
}): Promise<GetCustomerSegmentAutocompleteValues> => {
  return post<GetCustomerSegmentAutocompleteValues>(
    `/v1/customer/segment/filter-field-value`,
    {
      body: JSON.stringify({
        fieldId,
        search,
        searchAfter: nextSearchAfter,
        nextSearchAfter: undefined,
      }),
    },
    true
  );
};

export const getExcelMapping = async (
  sheets: SheetsDataPayload
): Promise<FieldsData> => {
  return post<FieldsData>(
    `/v1/groups/import-excel/mappings-metadata`,
    { body: JSON.stringify({ sheets }) },
    true
  );
};

export const uploadMappedExcelSheet = async (
  file: File,
  data: SheetMappingsRequest
): Promise<{ operationId: string }> => {
  const formData = new FormData();
  formData.append('file', file);
  Object.entries(formatObjectToFormDataKeyValuePairs(data, 'mappings')).forEach(
    ([key, value]) => formData.append(key, value)
  );

  return post<{ operationId: string }>(
    `/v1/groups/import-excel`,
    {
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    },
    true,
    false,
    false,
    true
  );
};

export const getExcelSheetsStatus =
  (): Promise<ImportExcelFileStatusResponse> => {
    return get<ImportExcelFileStatusResponse>(
      `/v1/groups/import-excel-active-operations`,
      undefined,
      true
    );
  };

export const deleteAllCustomers = async (otp: string): Promise<void> => {
  return deleteReq<void>(
    `/v1/customer/all`,
    {
      body: JSON.stringify({ code: otp }),
    },
    true
  );
};

export const deleteCustomers = async (
  params: DeleteCustomersType
): Promise<void> => {
  return deleteReq<void>(
    `/v1/customer`,
    {
      body: JSON.stringify(params),
    },
    true
  );
};
